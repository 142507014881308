import * as React from 'react';
import { graphql } from "gatsby";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import StaticPage from '../components/StaticPage/StaticPage';

type Props = {
  data: {
    regulationsData: {
      header: string,
      content: {
        raw: string,
      }
    }
  }
}

export const query = graphql`
  query($locale: String) {
    regulationsData: contentfulStaticPage(
      node_locale: { eq: $locale }
      slug: { eq: "regulations" }
    ) {
      header
      content {
        raw
      }
    }
  }
`

const Regulations: React.FC<Props> = ({data: { regulationsData }}: Props) => 
  <StaticPage
    header={regulationsData.header}
    description={documentToHtmlString(JSON.parse(regulationsData.content.raw))} />

export default Regulations